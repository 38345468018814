/* eslint-disable react/style-prop-object */
import { InvoicesStats } from 'api/models/invoicesStats';
import { InvoiceType } from 'api/models/invoiceType';
import { GetUserInvoicesQuery } from 'api/requests/getUserInvoicesQuery';
import { useUserService } from 'api/services/usersService';
import Spinner from 'components/spinner';
import { Badge, Dropdown, FlowbiteColors, Tabs, TabsRef } from 'flowbite-react';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import InvoicesListView from './InvoicesListView';


type InvoiceStatusMap = Partial<Record<InvoiceType, string>>;

export default function UserInvoicesLayout() {
  const [isLoadingData, setIsLoadingData] = useState<boolean>(true);
  let { status: statusParam } = useParams();
  const navigate = useNavigate();
  const tabsRef = useRef<TabsRef>(null);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [invoicesStats, setInvoicesStats] = useState<InvoicesStats>(null);
  const { getInvoices } = useUserService();

  const invoicesStatusMap: InvoiceStatusMap =
  {
    "Proforma": "Așteaptă plata",
    "Final": "Plătite"
  };

  const getUserInvoicesQuery: GetUserInvoicesQuery = {
    pageIndex: 0,
    pageSize: 10,
    includeStats: true,
    sortDescending: true
  };

  const invoicesStatuses = Object.keys(invoicesStatusMap) as InvoiceType[];

  const [selectedStatus, setSelectedStatus] = useState<InvoiceType>(statusParam as InvoiceType);

  const fetchInvoicesStats = async () => {
    setIsLoadingData(true);

    setInvoicesStats((await getInvoices(getUserInvoicesQuery)).data.stats);

    setIsLoadingData(false);
  };

  useEffect(() => {
    if (invoicesStats === null) {
      fetchInvoicesStats();
    }

    let status = statusParam;
    if (status === undefined) {
      status = "Proforma";
    }

    const matchedStatusIndex = invoicesStatuses.findIndex(s => s.toLowerCase() === status?.toLowerCase());
    if (matchedStatusIndex === -1) {
      navigate(`/error/not-found`);
      return;
    };

    setSelectedStatus(invoicesStatuses[matchedStatusIndex]);
    tabsRef.current?.setActiveTab(matchedStatusIndex);

    const handleResize = (() => window.innerWidth < 1200 ? setIsSmallScreen(true) : setIsSmallScreen(false));
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoicesStats, statusParam]);

  const onSelectInvoicesView = (selectedStatus: InvoiceType) => {
    setSelectedStatus(selectedStatus);
    navigate(`/my-account/invoices/${selectedStatus}`)
  }

  const onTabChanged = (tabIndex: number) => {
    const selectedStatus = invoicesStatuses[tabIndex];
    setSelectedStatus(selectedStatus);
    navigate(`/my-account/invoices/${selectedStatus}`);
  }

  const getInvoicesCountByStatus = (status: InvoiceType): number => {
    switch (status) {
      case "Proforma":
        return invoicesStats.awaitingPaymentCount;
      case 'Final':
        return invoicesStats.paidCount;
    }
  }

  const getSelectionTitle = (status: InvoiceType, badgeColor: keyof FlowbiteColors) =>
    <p className="flex justify-between items-center text-sm space-x-1 lg:space-x-2">
      <span>{invoicesStatusMap[status]}</span>
      <Badge color={badgeColor} size="xs" className="shrink-0 rounded-full">{getInvoicesCountByStatus(status)}</Badge>
    </p>

  const tabsLayout = () =>
    <Tabs aria-label="Facturi" style="underline" ref={tabsRef} onActiveTabChange={(tab) => onTabChanged(tab)}>
      {invoicesStatuses.map((status, index) =>
        <Tabs.Item key={index} active title={getSelectionTitle(status, "brand")}>
        </Tabs.Item>
      )}
    </Tabs>;

  const dropdownLayout = () =>
    <Dropdown
      theme={{ floating: { target: "w-full" } }}
      color="brand"
      label={getSelectionTitle(selectedStatus, "light")}>
      {
        invoicesStatuses.map((status, index) => (
          <div key={index}>
            <Dropdown.Item
              onClick={() => onSelectInvoicesView(status)}>
              {getSelectionTitle(status, "brand")}</Dropdown.Item>
            {index < invoicesStatuses.length - 1 && <Dropdown.Divider />}
          </div>
        ))}
    </Dropdown>;

  if (isLoadingData) {
    return <Spinner />
  }

  return (
    <div className="mx-2 md:mx-10">
      <h4 className="mb-2 text-xl md:text-2xl font-bold text-navy-700">Facturi</h4>
      {isSmallScreen ? dropdownLayout() : tabsLayout()}
      <div className="py-4">
        <InvoicesListView type={selectedStatus} />
      </div>
    </div>
  );
}