import { useAuth } from "api/useAuth";
import useAdminAuth from "auth/useAdminAuth";
import FooterContent from "components/footer";
import { Avatar, Dropdown, Navbar } from 'flowbite-react';
import { MdOutlineAccountCircle, MdOutlinePendingActions } from "react-icons/md";
import { Link, Outlet } from "react-router-dom";
import { useAppSelector } from "store";

const AdminLayout = () => {
  const { isAuthenticated, user, login, logout } = useAuth();
  const { isAdmin } = useAdminAuth();
  const storedUser = useAppSelector((state) => state.currentUser);

  return (
    <div className="min-h-screen flex flex-col w-full">

      <div className="sticky z-40 w-full bg-white top-0">
        <Navbar fluid rounded>
          <div className="flex w-full justify-end items-center md:order-2">
            <Dropdown
              arrowIcon={false}
              inline
              label={
                <Avatar alt="Utilizator" img={user.picture} rounded />
              }>
              {isAuthenticated && storedUser &&
                <Dropdown.Header>
                  {storedUser.firstName && storedUser.lastName && <span className="block text-sm">{storedUser.firstName} {storedUser.lastName}</span>}
                  <span className="block truncate text-sm font-medium">{storedUser.email}</span>
                </Dropdown.Header>}
              {isAuthenticated && <Dropdown.Item icon={MdOutlineAccountCircle}>
                <Link to="my-account" className="text-sm text-gray-800">
                  Contul meu</Link></Dropdown.Item>}
              {isAuthenticated && isAdmin() && <Dropdown.Item icon={MdOutlinePendingActions}>
                <Link to="admin/pending-users" className="text-sm text-gray-800">
                  Cereri de înregistrare</Link></Dropdown.Item>}
              {isAuthenticated && <Dropdown.Divider />}
              <Dropdown.Item className="text-left text-sm font-medium text-red-500 hover:text-red-500"
                onClick={() => {
                  if (isAuthenticated) {
                    logout();
                  } else {
                    login();
                  }
                }}>
                {isAuthenticated ? "Log Out" : "Log In"}
              </Dropdown.Item>
            </Dropdown>
          </div>
        </Navbar>
      </div>

      <main className="mx-1 md:mx-20 h-full mt-4 transition-all bg-lightPrimary relative flex flex-grow">
        <div className="flex-1">
          <Outlet />
        </div>
      </main>

      <FooterContent />
    </div>
  );
}

export default AdminLayout;
