import { BiddedLot } from "api/models/biddedLot";
import LotEndDate from "components/lot/LotEndDate";
import { neatDateWithWeekday } from "formatters/date";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSlug } from "utils/slugHelper";

const BiddedLotCard = (props: {
  biddedLot: BiddedLot;
}) => {
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const { slugify } = useSlug();
  const { biddedLot } = props;

  useEffect(() => {
    const handleResize = (() => window.innerWidth < 992 ? setIsSmallScreen(true) : setIsSmallScreen(false));
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);

  const getLotDisplayDate = (biddedLot: BiddedLot): JSX.Element => {
    switch (biddedLot.status) {
      case "Active":
        return <LotEndDate endDate={biddedLot.endsAtUtc} showLabel className="flex flex-col items-start lg:gap-1 text-sm lg:text-base font-semibold text-red-500" />
      default:
        return <p className="text-sm lg:text-base font-medium text-gray-400 italic">Închis</p>;
    }
  };

  const additionalInfo = (biddedLot: BiddedLot): JSX.Element => {
    return (
      <>{(biddedLot.status !== 'Active')
        ? <p className="text-sm lg:text-base font-medium text-gray-400 italic pl-2">Închis</p>
        : <div className="w-full flex flex-row justify-between items-center gap-2 lg:gap-4 pl-2 lg:pl-0">
          <div className="basis-2/5">{getLotDisplayDate(biddedLot)}</div>
          <div className="flex justify-between items-center gap-2 lg:gap-4">
            <div className="flex flex-col items-end gap-1 px-2">
              <p className="text-sm md:text-base">Oferta ta: <span className="font-bold">{biddedLot.currentUserLastBid?.amount} {biddedLot.currency}</span></p>
              <p className="text-xs md:text-base">Trimisă {neatDateWithWeekday(biddedLot.currentUserLastBid.registeredAtUtc)}</p>
            </div>
          </div>
        </div>}
      </>
    );
  };

  return (
    <Link to={`/lots/${slugify(biddedLot.name, biddedLot.id)}/details`}>
      <div className={`flex flex-row border border-gray-200 bg-white shadow-md max-w-full h-24 lg:h-48 ${isSmallScreen ? 'rounded-tl-lg rounded-tr-lg' : ' rounded-lg'}`}>
        <img
          src={biddedLot.firstImageUrl}
          alt=""
          className={`shrink-0 object-fill md:object-cover h-auto w-28 lg:w-72 ${isSmallScreen ? 'rounded-tl-lg' : 'rounded-s-lg'}`} />
        <div className="flex h-full flex-col grow min-w-0 justify-evenly lg:justify-between gap-1 lg:gap-2 p-2 lg:p-4">
          <article>
            <h3 className="font-semibold text-sm lg:text-lg lg:text-2xl font-bold tracking-tight text-gray-900 truncate">
              {biddedLot.name}
            </h3>
            <p className="mt-2 text-xs lg:text-sm lg:text-base italic">{biddedLot.country}, {biddedLot.city}</p>
            <div className="mt-2 flex items-center justify-between">
              <div>{biddedLot.totalBidsCount > 0 && <p className="font-semibold text-sm basis-1/3">{biddedLot.totalBidsCount} <span className="font-normal">{biddedLot.totalBidsCount > 1 ? 'licitări' : 'licitare'}</span> </p>}</div>
              {biddedLot.status === 'Active' &&
                <p className="text-sm md:text-base">Ultima ofertă: <span className="font-semibold">{biddedLot.currentPrice} {biddedLot.currency}</span></p>}
            </div>

          </article>
          {!isSmallScreen && <div>
            {additionalInfo(biddedLot)}
          </div>}
        </div>
      </div>
      {isSmallScreen &&
        <div className="w-full h-12 bg-gray-200 rounded-bl-lg rounded-br-lg flex items-center">
          {additionalInfo(biddedLot)}
        </div>
      }
    </Link>
  );
};

export default BiddedLotCard;
