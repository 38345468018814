import { Footer } from 'flowbite-react';

const FooterContent = () => {
  return (
    <Footer container className="mt-4 sticky bottom-0 z-40">
        <Footer.Copyright href="#" by="Licitas" year={2024} />
        <Footer.LinkGroup>
          {/* Use as={Link} feature for Footer.Links after Flowbite fix for this issue is merged - check here https://github.com/themesberg/flowbite-react/pull/1244 */}
          <Footer.Link href="#">About</Footer.Link>
          <Footer.Link href="#">Privacy Policy</Footer.Link>
          <Footer.Link href="#">Licensing</Footer.Link>
          <Footer.Link href="mailto:contact@licitas.ro" target="blank">Contact</Footer.Link>
        </Footer.LinkGroup>
      </Footer>
  );
};

export default FooterContent;
